import React, { useCallback, useState } from 'react';
import Editor from 'react-simple-code-editor';
import Papa from 'papaparse';
import './App.css';
import './sqlite';
import { createTable, getDBSchema, importTable, runSql } from './sqlite';
import type { TableSchema } from './sqlite';

import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css'; //Example style, you can use another

const SidebarButton = ({ text, onClick, disabled }: {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className={`
      border border-white py-2 px-4
      bold cursor-pointer select-none
      ${disabled ? 'pointer-events-none bg-gray-500' : 'bg-green-500'}
    `} onClick={onClick}>
      {text}
    </div>
  )
}

const SchemaItem = ({ schema }: { schema: TableSchema }) => {
  const [expand, setExpand] = React.useState(false)

  return (
    <div>
      <div className='cursor-pointer select-none' onClick={() => setExpand(!expand)}>
        {expand ? '-' : '+'} {schema.table}
      </div>
      { expand && schema.fields.map((field: any, i: any) => (
        <div key={i} className='pl-5 pt-1 text-xs'>{field[0]} <span className='text-gray-500'>{field[1]}</span></div>
      ))}
    </div>
  )
}

const Schema = ({ refresh, setRefresh }: { refresh: number, setRefresh: (d: number) => void }) => {
  const [schema, setSchema] = React.useState<TableSchema[]>([])

  React.useEffect(() => {
    setSchema([])
    getDBSchema().then((newSchema: TableSchema[]) => setSchema(newSchema))
  }, [refresh])

  return (
    <React.Fragment>
      <div className='flex-grow p-4 overflow-y-scroll'>
        {schema.length === 0 && 'No tables'}
        {schema.map((table, i) => <SchemaItem key={i} schema={table} />)}
      </div>
      <SidebarButton text='Refresh' onClick={() => setRefresh(new Date().getTime())}/>
    </React.Fragment>
  )
}

const fixValue = (value: any): string|number|undefined => {
  if (typeof value === 'string' && /^\d+$/.test(value)) {
    return Number(value)
  }

  if (
    typeof value === 'string' &&
    /^\d[0-9,]+\d$/.test(value) &&
    value.replace(/\d+/g, '') === ','
  ) {
    return Number(value.replace(',', '.'))
  }

  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'number') {
    return value
  }
}

const fixNumbers = (csv: any[]) => {
  return csv.map(row => {
    const newRow: any = {}

    Object.keys(row).forEach(key => {
      const newKey = key.replace(/[^A-Za-z0-9_]+/g, '')

      if (key === 'custom_slice_values') {
        Object.keys(row[key]).forEach(subkey => {
          newRow[subkey] = fixValue(row[key][subkey])
        })
      } else {
        const newValue = fixValue(row[key])

        if (typeof newValue !== 'undefined') {
          newRow[newKey] = newValue
        }
      }
    })

    return newRow
  })
}

const getSchema = (csv: any[]) => {
  const keys = Object.keys(csv[0])

  return keys.map((key) => {
    const isInteger = csv.filter((row: any) => (
      typeof row[key] === 'number' &&
      String(row[key]).indexOf('.') === -1
    )).length === csv.length

    const isReal = csv.filter((row: any) => (
      typeof row[key] === 'number'
    )).length === csv.length

    return {
      field: key,
      type: isInteger ? 'INTEGER' : isReal ? 'REAL' : 'TEXT'
    }
  })
}

const importCSV = (table: string, file: File) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (data) => {
        const csv = fixNumbers(data.data)

        createTable(table, getSchema(csv)).then(() => {
          return importTable(table, csv)
        })
        .then(() => resolve(undefined))
        .catch((e: any) => {
          console.error(e?.result?.input?.args?.sql)
          console.error(e?.result?.message)
          console.error(e)
          alert('Error while importing data, check console')
          resolve(undefined)
        })
      },
      error: (error) => {
        console.error(error)
        alert('Error while parsing CSV, check console')
      }
    })
  })
}

const ImportFromCSV = ({ setRefresh }: { setRefresh: (d: number) => void }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const selectFile = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const createTable = () => {
    const file = inputRef.current?.files && inputRef.current.files[0];

    if (!file) {
      return
    }

    const table = prompt('Table name')

    if (!table) {
      return alert('Table name can not be empty')
    }

    importCSV(table, file).then(() => {
      setRefresh(new Date().getTime())
    })

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <React.Fragment>
      <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={createTable} />
      <SidebarButton text='Import from CSV' onClick={selectFile} />
    </React.Fragment>
  )
}

const ImportFromStats = ({ setRefresh }: { setRefresh: (d: number) => void}) => {
  const [loading, setLoading] = React.useState(false)

  const click = async () => {
    if (!localStorage.token) {
      return window.open('token.html')
    }

    const link = prompt('Stats link')

    if (!link || !/^https:\/\/stats\.propellerads\.com\/a\/stat\?profile=\d+$/.test(link)) {
      return alert('Invalid link')
    }

    const table = prompt('New table name')

    if (!table) {
      return alert('Table name can not be empty')
    }

    const [, id] = link.split('=')

    setLoading(true)

    try {
      const profile = await (await fetch('https://statsv2-backend.propellerads.com/', {
        method: 'POST',
        body: `{"action":"profile","params":{"method":"get","id":"${id}"},"token":"${localStorage.token}"}`,
      })).json()

      try {
        const metrics = profile.data.profile?.params?.metrics

        if (metrics && metrics.indexOf('impression_notices') === -1) {
          profile.data.profile.params.metrics += ',impression_notices'
        }
      } catch (e) {}

      const stats = await (await fetch('https://statsv2-backend.propellerads.com/', {
        method: 'POST',
        body: `{"action":"stat","params":${JSON.stringify(profile.data.profile)},"token":"${localStorage.token}"}`,
      })).json()

      const data = fixNumbers(stats.data.stat.data)

      console.log('stats data', data)

      try {
        await createTable(table, getSchema(data))
        await importTable(table, data)
      } catch (e) {
        console.error(e)
        alert('Failed to import downloaded data')
      }
    } catch (e) {
      console.error(e)
      alert('Failed to download data from stats')
    }

    setLoading(false)
    setRefresh(new Date().getTime())
  }

  const text = loading ? 'Importing...' : 'Import from stats'

  return <SidebarButton text={text} disabled={loading} onClick={click}/>
}

const Sidebar = ({ refresh, setRefresh }: { refresh: number, setRefresh: (d: number) => void}) => (
  <div className='w-1/5 border-r-2 flex flex-col'>
    <Schema refresh={refresh} setRefresh={setRefresh} />
    <ImportFromCSV setRefresh={setRefresh} />
    <ImportFromStats setRefresh={setRefresh} />
  </div>
)

const SQL = ({ value, onChange }: { value: string, onChange: (val: string) => void}) => (
  <div className='h-1/5 border-b-2'>
    <Editor className='w-full h-full' padding={10} value={value} onValueChange={(code) => onChange(code)} highlight={code => highlight(code, languages.sql, 'sql')} />
  </div>
)

const ActionPanel = ({ onRun, onExport }: { onRun: () => void, onExport: () => void }) => (
  <div className='flex justify-between'>
    <div className='border-l-2 px-4 bg-green-500 bold select-none cursor-pointer' onClick={onRun}>
      Run (Ctrl+Enter)
    </div>
    <div className='border-l-2 px-4 bg-green-500 bold select-none cursor-pointer' onClick={onExport}>
      Export CSV
    </div>
  </div>
)

const Results = ({ res }: { res: SqlRes }) => (
  <div className='h-4/5 border-t-2 overflow-scroll'>
    {
      res.loading && 'Executing query...'
    }
    {
      res.error && res.error
    }
    {
      res.result && !res.result.length && 'Empty response'
    }
    {
      res.result && !!res.result.length && (
        <table className='border border-black text-xs'>
          <thead>
            <tr>
              {res.result[0].map((cell: any, i: any) =>
                <th key={i} className='border py-1 px-2'>{cell}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {
              res.result.slice(1).map((row, i) => <tr key={i}>
                {row.map((cell: any, i: any) =>
                  <td key={i} className='border py-1 px-2'>{cell}</td>
                )}
              </tr>)
            }
          </tbody>
        </table>
      )
    }
  </div>
)

interface SqlRes {
  result?: any[]
  error?: any
  loading?: boolean
}

function App() {
  const [sql, setSql] = useState('')
  const [res, setRes] = useState<SqlRes>({})
  const [refresh, setRefresh] = useState(new Date().getTime())

  const run = useCallback(() => {
    if (res.loading) {
      return
    }

    setRes({ loading: true })
    runSql(sql).then((newRes: SqlRes) => {
      setRes(newRes)
      setRefresh(new Date().getTime())
    })
  }, [sql, res])

  const doExport = useCallback(() => {
    if (!res.result) {
      return alert('Nothing to export')
    }

    const csv = Papa.unparse(res.result)
    const link = document.createElement('a');

    link.setAttribute('href', 'data:text/csv;charset=utf8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'statslite_'+(new Date().toISOString())+'.csv');
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [res])

  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'Enter') {
        run()
      }
    }

    window.addEventListener('keydown', listener)

    return () => window.removeEventListener('keydown', listener)
  }, [run])

  return (
    <div className='h-screen flex'>
      <Sidebar refresh={refresh} setRefresh={setRefresh} />
      <div className='w-4/5 flex flex-col'>
        <SQL value={sql} onChange={setSql} />
        <ActionPanel onRun={run} onExport={doExport}/>
        <Results res={res} />
      </div>
    </div>
  );
}

export default App;
