import { sqlite3Worker1Promiser } from '@sqlite.org/sqlite-wasm';

const getDB = async () => {
  try {
    const promiser = await new Promise((resolve) => {
      const _promiser = sqlite3Worker1Promiser({
        onready: () => {
          resolve(_promiser);
        },
      });
    });

    const { dbId } = await promiser('open', {
      filename: 'file:worker-promiser.sqlite3?vfs=opfs',
    });

    return (sql) => {
      const rows = [];

      return new Promise((resolve, reject) => {
        promiser('exec', { dbId, sql, callback: (result) => {
          if (result.row) {
            rows.push(result.row);
          }
        }}).then((result) => {
          const header = result?.result?.columnNames

          if (header) {
            rows.unshift(header)
          }

          resolve(rows)
        }).catch(e => {
          reject(e?.result?.message)
        })
      })
    }
  } catch (e) {
    console.log(e.result.message);
  }
}

let db
const waitDB = getDB()

waitDB.then(_db => {
  db = _db;
})

export const getDBSchema = async () => {
  await waitDB
  const rows = await db('SELECT * FROM sqlite_schema');
  const tables = rows.slice(1).map(row => row[1])
  const schema = []

  for (let table of tables) {
    const res = await db(`pragma table_info(${table})`)
    schema.push({
      table,
      fields: res.map(row => [row[1], row[2]])
    })
  }

  return schema
}

export const createTable = async (name, schema) => {
  const fields = schema.map(({ field, type }) => [field, type].join(' ')).join(',')

  await db(`CREATE TABLE IF NOT EXISTS ${name} (${fields})`)
}

export const importTable = async (name, data) => {
  console.log(data)
  const fields = Object.keys(data[0])

  const values = data.map(row => ['(', ')'].join(fields.map(key => {
    if (typeof row[key] === 'number') {
      return row[key]
    }

    return ["'", "'"].join(String(row[key]).replace(/'/g,"''"))
  }).join(','))).join(',')

  await db(`INSERT INTO ${name} (${fields.join(',')}) VALUES ${values}`)
}

export const runSql = async (sql) => {
  try {
    return {
      result: await db(sql),
    }
  } catch (e) {
    return {
      error: e,
    }
  }
}
